import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { graphql } from "gatsby";

import {
  CardTestimonial,
  HeroHeader,
  Page,
  Section,
  usePageStyles,
} from "components";

const PageAbout = ({
  data: {
    config: { header, seo },
    reviews,
  },
}) => {
  const styles = usePageStyles();

  return (
    <Page seo={seo}>
      <HeroHeader {...header} />
      <Section className="feature">
        <Typography className={styles.heading} component="h6" variant="h4">
          Clean commercial is a network of like-minded people.
        </Typography>
        <Typography
          className={styles.context}
          component="p"
          variant="subtitle1"
        >
          Above all other goals, we are about connecting with people - doing
          work that we love with people who understand each other. We believe
          that each job is special, that each customer has their own vision, and
          it's our job to bring that vision to life.
        </Typography>
        <Typography
          className={styles.context}
          component="p"
          variant="subtitle1"
        >
          We spare no expense to achieve our goals - like never skipping on the
          tiniest of details, and the end result speaks for itself. Our main
          concern before we finish any project is leaving our customer fully
          satisfied with our work, because for us, building trustworthy
          relationships is what its all about.
        </Typography>
      </Section>
      {reviews && (
        <Section>
          {reviews.edges.map(({ node }) => (
            <CardTestimonial key={node.date} {...node} />
          ))}
        </Section>
      )}
    </Page>
  );
};

PageAbout.propTypes = {
  data: PropTypes.shape({
    config: PropTypes.shape({
      header: PropTypes.shape({
        buttons: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string,
            path: PropTypes.string,
          })
        ),
        context: PropTypes.string,
        heading: PropTypes.string,
        minHeight: PropTypes.string,
      }),
      id: PropTypes.string,
      seo: PropTypes.shape({
        title: PropTypes.string,
      }),
    }),
    reviews: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            client: PropTypes.string,
            context: PropTypes.string,
            date: PropTypes.string,
            feature: PropTypes.bool,
            heading: PropTypes.string,
            services: PropTypes.arrayOf(
              PropTypes.shape({
                category: PropTypes.string,
                type: PropTypes.string,
              })
            ),
          }),
        })
      ),
    }),
  }),
};

export const aboutPageQuery = graphql`
  query AboutPageQuery {
    config: pagesYaml(id: { eq: "PageAbout" }) {
      header {
        buttons {
          label
          path
        }
        context
        heading
      }
      id
      seo {
        title
      }
    }
    reviews: allReviewsYaml(
      filter: { feature: { in: true } }
      limit: 1
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          client
          context
          date(formatString: "MMM. DD, YYYY")
          feature
          heading
        }
      }
    }
  }
`;

export default PageAbout;
